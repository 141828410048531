import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { ButtonGroup, Button, Card, CardHeader, CardBody, CardTitle, Table, Row, Col, UncontrolledTooltip } from 'reactstrap';

import { report } from '../../../api';
import UserContext from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { ReportDB } from 'api/reports';
import { ReportTableRecord } from './ReportTableRecord';
import { ModalOverlay, ModalOverlayDelete } from 'components/Modal/Modal';
import { FaInfo, FaPlus, FaSearch } from 'react-icons/fa';
import { ResourceNotFoundException } from '../../../api/exceptions/ResourceNotFoundException';
import { UnauthorizedException } from '../../../api/exceptions/UnauthorizedException';
import toast from 'react-hot-toast';
import { THSorter } from 'components/TableSorting/TableSorting';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';
import { FailedToFetchException } from 'api/exceptions/FailedToFetchException';

export const ReportTable = () => {
	const navigate = useNavigate();
	const { jwt, setDisplayUserLoginOverlay } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState<boolean>(false);

	const [data, setData] = useState<Array<ReportDB>>([]);

	const [sortBy, setSortBy] = useState<keyof ReportDB>('title');
	const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');

	const [copyRecordID, setCopyRecordID] = useState<null | string>(null);
	const [tableRecordDeleteID, setTableRecordDeleteID] = useState<null | string>(null);

	const getContents = async () => {
		setIsLoading(true);
		try {
			const reports = await report.list(jwt, { sort: sortBy, order: sortDir, page_size: 10000 });

			setData(reports);
		} catch (error) {
			setLoadingError(true);
			if (error instanceof UnauthorizedException) {
				setDisplayUserLoginOverlay(true);
			} else if (error instanceof FailedToFetchException) {
				// Nothing we need to do
			} else if (error instanceof ForbiddenException) {
				toast.error('You do not have permission to perform this action');
			} else {
				console.error('error');
				console.error(error);
				Sentry.captureException(error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getContents();
	}, []);

	const handleToolbarAddClick = () => {
		navigate({ pathname: `${location.pathname}/add` });
	};

	const handleRecordEdit = (docID: string) => {
		navigate({ pathname: `${location.pathname}/${docID}` });
	};

	const handleRecordCopy = (docID: string) => {
		setCopyRecordID(docID);
	};

	const handleRecordDelete = (docID: string) => {
		setTableRecordDeleteID(docID);
	};

	const handleTableRecordDeleteConfirm = async () => {
		try {
			await report.delete(jwt, tableRecordDeleteID!);

			const recordIndex = data.findIndex((record) => record.docID === tableRecordDeleteID);

			if (recordIndex > -1) {
				data.splice(recordIndex, 1);
			}

			setData(data);
			setTableRecordDeleteID(null);
		} catch (error) {
			if (error instanceof ResourceNotFoundException) {
				toast.error('Record already deleted');
				setTableRecordDeleteID(null);
			} else {
				toast.error('Unknown error occurred');

				console.error('error');
				console.error(error);
				Sentry.captureException(error);
			}
		}
	};

	const handleTableRecordDeleteConfirmCancel = () => {
		setTableRecordDeleteID(null);
	};

	const handleCopyConfirm = async () => {
		try {
			const response = await report.copy(jwt, copyRecordID!);

			setData((prevData) => [...prevData, response]);
			setCopyRecordID(null);
		} catch (error) {
			console.error('error');
			console.error(error);
			Sentry.captureException(error);
		}
	};

	const handleCopyCancel = () => {
		setCopyRecordID(null);
	};

	const handleSortHeader = (column: keyof ReportDB) => {
		if (sortBy === column) {
			setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(column);
			setSortDir('desc');
		}

		getContents();
	};

	const handleRecordRunComplete = (docID: string, last_run: Date) => {
		const newData = [...data];
		const index = newData.findIndex((record) => record.docID === docID);

		if (index > -1) {
			newData[index].last_run = last_run;
		}

		setData(newData);
	};

	return (
		<div className="report-table">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<div className="pull-right">
								<Button color="info" className="d-flex align-items-center gap-1" onClick={handleToolbarAddClick}>
									<FaPlus /> Add Report
								</Button>
							</div>
							<CardTitle tag="h4">Reports</CardTitle>
						</CardHeader>
						<CardBody>
							<Table hover>
								<thead className="text-primary">
									<tr>
										<th style={{ width: '3%' }}></th>
										<THSorter
											sortBy={sortBy === 'title'}
											sortDir={sortDir}
											onClick={() => {
												handleSortHeader('title');
											}}
											style={{ width: '40%' }}
										>
											Title
										</THSorter>
										<THSorter
											sortBy={sortBy === 'type'}
											sortDir={sortDir}
											onClick={() => {
												handleSortHeader('type');
											}}
										>
											Type
										</THSorter>
										<THSorter
											sortBy={sortBy === 'outputFormat'}
											sortDir={sortDir}
											onClick={() => {
												handleSortHeader('outputFormat');
											}}
										>
											Output
										</THSorter>
										<THSorter
											sortBy={sortBy === 'last_run'}
											sortDir={sortDir}
											onClick={() => {
												handleSortHeader('last_run');
											}}
											type="numeric"
										>
											Last Run
										</THSorter>
										<th className="text-right" style={{ width: '20%' }}>
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{loadingError ? (
										<tr>
											<td colSpan={6}>There was an error loading data.</td>
										</tr>
									) : isLoading ? (
										<tr>
											<td colSpan={6}>Loading...</td>
										</tr>
									) : data != null && data.length > 0 ? (
										data.map((record) => {
											return (
												<ReportTableRecord
													key={record.docID}
													data={record}
													onEdit={handleRecordEdit}
													onCopy={handleRecordCopy}
													onDelete={handleRecordDelete}
													onRunComplete={handleRecordRunComplete}
												/>
											);
										})
									) : (
										<tr>
											<td colSpan={5}>No reports found.</td>
										</tr>
									)}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<ModalOverlay
				show={copyRecordID != null}
				icon={FaInfo}
				title="Copy Report"
				primaryActionCallback={handleCopyConfirm}
				primaryActionText="Yes, Copy"
				secondaryActionCallback={handleCopyCancel}
			>
				Are you sure you want to copy this report?
			</ModalOverlay>
			<ModalOverlayDelete
				show={tableRecordDeleteID != null}
				title="Delete Report"
				primaryActionCallback={handleTableRecordDeleteConfirm}
				secondaryActionCallback={handleTableRecordDeleteConfirmCancel}
			>
				This action cannot be undone.
			</ModalOverlayDelete>
		</div>
	);
};
